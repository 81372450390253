import { Component, Vue } from 'vue-property-decorator';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import { AxiosError } from 'axios';
import { CostSpecification } from '@/models/CostSpecification';

@Component<Check>({})
export default class Check extends Vue {
  // #region Class properties
  protected isLoading = false;

  protected isFailed = false;

  protected isSucceeded = false;

  protected errors: Error = {};

  // Form
  protected form: {[key: string]: null | File | string} = {
    file: null,
    name: '',
  };

  // file uploading
  protected isUploaded = false;

  protected uploadedFile = '';

  protected acceptedFileTypes: string[] = [
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-excel.addin.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  ];
  // #endregion

  // #region Class methods

  protected close() {
    this.$emit('input', false);
    this.$emit('close');
  }

  protected handleItemDropped(file: File) {
    if (! file) {
      return;
    }

    this.form.file = file;
    const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
    fileUploadComponent.uploaded();
    this.create();
  }
  // #endregion

  // #region Async methods

  protected async create() {
    const payload = {
      file: this.form.file,
      specification_number: 'dry-run',
      dry_run: 1,
    };

    this.isSucceeded = false;
    this.isFailed = false;
    this.isLoading = true;

    const response = await new CostSpecification()
      .create(payload)
      .catch((error: AxiosError) => {
        this.errors = error.response?.data.errors || {};
        this.isFailed = true;
      });

    if (response) {
      this.isSucceeded = true;
      this.$emit('eventUpdated');
    }

    this.isLoading = false;
  }
  // #endregion
}

interface ErrorEntry {
  level: 'error' | 'warning',
  type: 'unknown_user' | 'unknown_report' | 'unknown_type' | 'unknown_level' | 'mismatch_level' | 'missing_fields',
  message: string,
}

export interface Error {
  message?: {
    errors?: Record<string, ErrorEntry>;
    row_count?: number;
    success?: number;
    failed_row?: number;
  }
}
