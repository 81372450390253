var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ACard",
    { attrs: { title: "Specificatie checker" } },
    [
      _vm.isLoading
        ? _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [_c("ALoader", { staticClass: "tw-my-24" })],
            1
          )
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "v-flex",
              { attrs: { xs12: "" } },
              [
                _c(
                  "v-layout",
                  {
                    staticClass: "fileUpload__container",
                    attrs: { "fill-height": "", "pa-1": "", wrap: "" }
                  },
                  [
                    _c("MiFileUpload", {
                      ref: "fileUpload",
                      attrs: {
                        filePath: _vm.uploadedFile,
                        clearAfterUpload: false,
                        multiple: false,
                        acceptedFileTypes: _vm.acceptedFileTypes,
                        fileIcon: "insert_drive_file"
                      },
                      on: {
                        itemDropped: function($event) {
                          return _vm.handleItemDropped($event)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm.isSucceeded
              ? [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "text-center": "" } },
                    [
                      _c(
                        "ATitle",
                        { staticClass: "tw-my-16 tw-text-success-500" },
                        [
                          _vm._v(
                            "Het valideren van de specificatie is geslaagd."
                          )
                        ]
                      ),
                      _vm.errors &&
                      _vm.errors.message &&
                      _vm.errors.message.errors
                        ? _c(
                            "v-layout",
                            {
                              staticClass: "item__list item__list--overflow",
                              attrs: { wrap: "" }
                            },
                            [
                              _vm._l(_vm.errors.message.errors, function(
                                errors,
                                errorsIndex
                              ) {
                                return [
                                  _vm._l(errors, function(error, index) {
                                    return [
                                      _c(
                                        "v-flex",
                                        {
                                          key:
                                            "mismatch_level-" +
                                            index +
                                            "-" +
                                            errorsIndex,
                                          staticClass: "list__item",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { "align-center": "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { shrink: "" } },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color:
                                                          error.level == "error"
                                                            ? "warning"
                                                            : "alert"
                                                      }
                                                    },
                                                    [_vm._v("warning")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { shrink: "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(error.message)
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  })
                                ]
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { xs12: "", "text-center": "" } }, [
                    _c(
                      "div",
                      { staticClass: "big-info-icon" },
                      [
                        _c("v-icon", { attrs: { color: "success" } }, [
                          _vm._v("check_circle_outline")
                        ])
                      ],
                      1
                    )
                  ])
                ]
              : _vm._e(),
            _vm.isFailed
              ? [
                  _c("ATitle", { staticClass: "tw-my-16 tw-text-error-500" }, [
                    _vm._v("Validatie mislukt.")
                  ]),
                  _vm.errors && _vm.errors.message && _vm.errors.message.errors
                    ? _c(
                        "v-layout",
                        {
                          staticClass: "item__list item__list--overflow",
                          attrs: { wrap: "" }
                        },
                        [
                          _vm._l(_vm.errors.message.errors, function(
                            errors,
                            errorsIndex
                          ) {
                            return [
                              _vm._l(errors, function(error, index) {
                                return [
                                  _c(
                                    "v-flex",
                                    {
                                      key:
                                        "mismatch_level-" +
                                        index +
                                        "-" +
                                        errorsIndex,
                                      staticClass: "list__item",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { "align-center": "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { shrink: "" } },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color:
                                                      error.level == "error"
                                                        ? "warning"
                                                        : "alert"
                                                  }
                                                },
                                                [_vm._v("warning")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { shrink: "" } },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(error.message))
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              })
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ]
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }